import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Grid, Typography, Button } from "@mui/material";

import {
  PagingParams,
  FilterParams,
  SortParams,
  NotificationBanners,
  NotificationBanner
} from "../../types";

import NotificationsTable from "./NotificationsTable";
import paginate from "../../shared/datagridUtils/paginate";
import { right } from "@popperjs/core";
import { useHistory } from "react-router";

type PropTypes = {
  notificationBanners: Array<NotificationBanner>;
};

// Exporting enum and functions for testing purposes
export enum FilterableColumns {
  status = "status",
  notes = "notes",
  startDate = "startDate",
}

export const filterNotificationBannersByColumn = (
  query: string,
  filteredColumn: FilterableColumns,
  formValues?: NotificationBanner[]
): NotificationBanner[] => {
  let filteredNotificationBanners: NotificationBanner[];

  switch (filteredColumn) {
    case FilterableColumns.status:
      filteredNotificationBanners = formValues.filter((notificationBanner) =>
        notificationBanner.status?.toLowerCase().includes(query.toLowerCase())
      );
      break;
    case FilterableColumns.notes:
      filteredNotificationBanners = formValues.filter((notificationBanner) =>
        notificationBanner.notes?.toLowerCase().includes(query.toLowerCase())
      );
      break;
    case FilterableColumns.startDate:
      filteredNotificationBanners = formValues.filter((notificationBanner) =>
        notificationBanner.eventDate?.toString()?.toLowerCase().includes(query.toLowerCase())
      );
      break;
  }

  return filteredNotificationBanners;
};

export const sortBanners = (
  formValues: NotificationBanner[],
  sortParams: SortParams
): NotificationBanner[] => {
  const sortedData = formValues.sort((a, b) => {
    const isDesc = sortParams.sortOrder === "desc";
    if (a[sortParams.sortField] < b[sortParams.sortField]) {
      return isDesc ? 1 : -1;
    }

    if (a[sortParams.sortField] > b[sortParams.sortField]) {
      return isDesc ? -1 : 1;
    }
    return 0;
  });

  return sortedData;
};

const SummaryForm = ({ notificationBanners }: PropTypes) => {
  const buttonTheme = createTheme({ palette: { primary: { main: "#0A3450" } } })
  const [formValues, setFormValues] = useState(notificationBanners);
  const history = useHistory();

  const [pageParams, setPageParams] = useState<PagingParams>({
    page: 0,
    size: 5,
    filterParams: {} as FilterParams,
    sortParams: {} as SortParams,
  });

  const handleFilterChange = (filterParams: FilterParams) => {
    setPageParams({ ...pageParams, filterParams, page: 0 });
    var filteredNotificationBanners;

    filteredNotificationBanners = filterNotificationBannersByColumn(
      filterParams.filterQuery,
      FilterableColumns[filterParams.filterColumn],
      formValues
    );

    const { startIndex, endIndex } = paginate(
      formValues.length,
      0,
      pageParams.size
    );
    setPagedNotificationBanners({
      ...pagedNotificationBanners,
      notificationBanners: filteredNotificationBanners.slice(startIndex, endIndex),
      totalCount: filteredNotificationBanners.length,
    });
  };

  const sortNotificationBanners = (sortParams: SortParams): void => {
    setPageParams({ ...pageParams, sortParams, page: 0 });

    const sortedData = sortBanners(formValues, sortParams);

    const { startIndex, endIndex } = paginate(
      formValues.length,
      0,
      pageParams.size
    );
    setPagedNotificationBanners({
      ...sortedData,
      notificationBanners: sortedData.slice(startIndex, endIndex),
      totalCount: sortedData.length,
    });
  };

  const handleSortChange = (sortParams: SortParams) => {
    sortNotificationBanners(sortParams);
  };

  const handlePageChange = (newPage: number, newSize: number) => {
    setPageParams({
      ...pageParams,
      page: newPage,
      size: newSize,
    } as PagingParams);
    const { startIndex, endIndex } = paginate(
      formValues.length,
      newPage,
      newSize
    );
    setPagedNotificationBanners({
      ...pagedNotificationBanners,
      notificationBanners: formValues.slice(startIndex, endIndex),
    });
  };

  const onNewNotificationButtonClicked = (event) => {
    history.push("/new");
  };

  const [pagedNotificationBanners, setPagedNotificationBanners] =
    useState<NotificationBanners>({
      notificationBanners: Array.isArray(notificationBanners)
        ? notificationBanners.slice(0, pageParams.size)
        : [],
      totalCount: notificationBanners?.length,
    });

  return (
    <form data-testid="banner-form">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="stretch"
        alignItems="stretch"
        paddingTop={2}
      >
        <Grid item container spacing={2} xl={6} lg={6} md={6} xs={6}>
          <Grid item xl={10} lg={10} md={10} xs={10}>
            <Typography variant="h5" color="gray" style={{ lineHeight: 0.8 }}>
              Notifications
            </Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={2} xl={6} lg={6} md={6} xs={6}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <ThemeProvider theme={buttonTheme}>
              <Button
                data-testid="banner-newButton"
                variant="contained"
                color="primary"
                onClick={onNewNotificationButtonClicked}
                style={{
                  width: 160,
                  height: 35,
                  fontSize: 12,
                  float: right,
                }}
              >
                New Notification
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} alignItems="start">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <NotificationsTable
            dataTestId="banner-messages"
            notificationBannerData={pagedNotificationBanners}
            handleFilterChange={handleFilterChange}
            handleSortChange={handleSortChange}
            handlePageChange={handlePageChange}
            pagingParams={pageParams}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SummaryForm;
