import { useState, useRef, useMemo } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Grid,
  TextField,
  Button,
  ThemeProvider,
  createTheme,
  IconButton,
  Typography,
  FormControl,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import {
  NotificationBanner,
  PagedStacks,
  PagingParams,
  FilterParams,
  SortParams,
  Stack,
  QueryKeys,
} from "../../types";

import Stacks from "./Stacks";
import DataLoadingContainer from "../../shared/dataLoadingContainer";
import { nameOf } from "../../shared/utils/helpers";
import { addDays } from "../../shared/utils/dateUtils";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types";
import { DateTimeValidationError, DateValidationError } from "@mui/x-date-pickers";

type PropTypes = {
  notificationBanner: NotificationBanner;
  isPosting: boolean;
  isEdit: boolean;
  postNotificationBanner: (NotificationBanner) => void;
  putNotificationBanner: (NotificationBanner) => void;
  cancel: () => void;
  getStacks: (pagingParams: PagingParams) => Promise<PagedStacks>;
  deleteNotificationBanner: (NotificationBanner) => void;
  goBackToPreviousPage: () => void;
};

const EditForm = ({
  notificationBanner,
  postNotificationBanner,
  putNotificationBanner,
  goBackToPreviousPage,
  cancel,
  getStacks,
  isPosting,
  isEdit,
  deleteNotificationBanner,
}: PropTypes) => {
  const [formValues, setFormValues] = useState(notificationBanner);
  const [error, setError] = useState({eventDateError: null, displayDateError: null, removeDateError: null});

  const isDateInvalid = useMemo(() => {
    return error.displayDateError != null || error.removeDateError != null || error.eventDateError != null;
  }, [error.displayDateError, error.removeDateError, error.eventDateError]);

  const buttonTheme = createTheme({
    palette: { primary: { main: "#0A3450" } },
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of the Enter key
    }
  };

  const onDeleteButtonClicked = (event) => {
    deleteNotificationBanner(formValues);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const onGoBackClicked = (event) => {
    goBackToPreviousPage();
  };

  const handleDateChange = (e: Date, field: string, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
    if (e instanceof Date && !isNaN(e.valueOf()) && context.validationError === null) {
      e.setMilliseconds(0);
      e.setSeconds(0);
      let newFormValues = { ...formValues, [field]: e };

      // enforce display date to be before event date and remove date to be after event date
      if(field === nameOf(formValues, (obj) => obj.eventDate)) {
        if(e < formValues.displayDate) {
          const displayDate = e;
          displayDate.setMilliseconds(0);
          displayDate.setSeconds(0);
          newFormValues[nameOf(formValues, (obj) => obj.displayDate)]= displayDate;
        }
        if(e > formValues.removeDate) {
          const removeDate = addDays(e, 1);
          removeDate.setMilliseconds(0);
          removeDate.setSeconds(0);
          newFormValues[nameOf(formValues, (obj) => obj.removeDate)] = removeDate;
        }
      }

      setFormValues({ ...newFormValues });
    }
  };

  const [pageParams, setPageParams] = useState<PagingParams>({
    page: 0,
    size: 5,
    filterParams: {} as FilterParams,
    sortParams: { sortField: "name", sortOrder: "asc", } as SortParams,
  });

  const filterStacksByName = (query: string, page: number): Stack[] => {
    const filteredStacks = formValues.stacks.filter((stack) =>
      stack.name.toLowerCase().includes(query.toLowerCase())
    );
    return filteredStacks;
  };

  const handleFilterChange = (filterParams: FilterParams) => {
    setPageParams({ ...pageParams, filterParams, page: 0 });
  };

  const handleSortChange = (sortParams: SortParams) => {
    setPageParams({ ...pageParams, sortParams });
  };

  const handlePageChange = (newPage: number, newSize: number) => {
    setPageParams({ ...pageParams, page: newPage, size: newSize });
  };

  const onStackClicked = (params) => {};

  const handleStackSelectedChange = (selected: boolean, stack: Stack) => {
    if (selected) {
      setFormValues({
        ...formValues,
        stacks: [
          ...formValues.stacks,
          {
            ...stack,
            selected: selected,
          },
        ],
      });
    } else {
      setFormValues({
        ...formValues,
        stacks: formValues.stacks.filter((el) => el.name !== stack.name),
      });
    }
  };

  const getStackSelectedValue = (stack: Stack) => {
    const itemNotSelected =
      formValues.stacks.find((el) => el.name === stack.name) == undefined;
    return !itemNotSelected;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      putNotificationBanner(formValues);
    } else {
      postNotificationBanner(formValues);
    }
  };

  const onCancelClicked = (event) => {
    event.preventDefault();
    cancel();
  };

  const queryKeys = [
    QueryKeys.GetStacks,
    pageParams.page,
    pageParams.size,
    pageParams.sortParams.sortOrder,
    pageParams.sortParams.sortField,
    pageParams.filterParams.filterQuery,
    pageParams.filterParams.filterColumn,
  ];

  const queryFn = async () => {
    const stacks = await getStacks(pageParams);
    return stacks;
  };

  const containerRef = useRef(null);

  return (
    <form onSubmit={handleSubmit} data-testid="banner-form">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="stretch"
        alignItems="stretch"
        paddingTop={2}
      >
        <Grid item xl={2} lg={2} md={2} xs={2}>
          <IconButton
            data-testid="banner-goBackButton"
            size="medium"
            sx={{ padding: 0, minHeight: 0 }}
            onClick={onGoBackClicked}
          >
            <KeyboardArrowLeftIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <Grid item xl={8} lg={8} md={8} xs={10}>
          <Typography variant="h5" color="gray" style={{ lineHeight: 0.8 }}>
            {isEdit ? "Edit Notification" : "New Notification"}
          </Typography>
        </Grid>
        {isEdit ? (
          <Grid item xl={2} lg={2} md={2} xs={12}>
            <ThemeProvider theme={buttonTheme}>
              <Button
                data-testid="banner-deleteButton"
                variant="contained"
                color="error"
                onClick={onDeleteButtonClicked}
                style={{
                  width: 130,
                  height: 25,
                  fontSize: 13,
                  float: "right",
                }}
              >
                Delete
              </Button>
            </ThemeProvider>
          </Grid>
        ) : null}
        <Grid item container spacing={2} xl={6} lg={6} md={6} xs={6}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <div ref={containerRef} onKeyDown={handleKeyPress}>
              <DateTimePicker
                label="Event Date"
                onChange={(event, context) =>
                  handleDateChange(
                    event,
                    nameOf(formValues, (obj) => obj.eventDate),
                    context
                  )
                }
                onError={(newError) => setError({...error, eventDateError: newError})}
                value={formValues.eventDate}
                slotProps={{
                  textField: { name: "eventDate" },
                  actionBar: { actions: ["today"] },
                }}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={6}>
            <div ref={containerRef} onKeyDown={handleKeyPress}>
              <DateTimePicker
                label="Display Date"
                onChange={(event, context) =>
                  handleDateChange(
                    event,
                    nameOf(formValues, (obj) => obj.displayDate),
                    context
                  )
                }
                onError={(newError) => setError({...error, displayDateError: newError})}
                maxDateTime={formValues.eventDate}
                value={formValues.displayDate}
                slotProps={{
                  textField: { name: "displayDate" },
                  actionBar: { actions: ["today"] },
                }}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={6}>
            <div ref={containerRef} onKeyDown={handleKeyPress}>
              <DateTimePicker
                label="Remove Date"
                onChange={(event, context) =>
                  handleDateChange(
                    event,
                    nameOf(formValues, (obj) => obj.removeDate),
                    context
                  )
                }
                onError={(newError) => setError({...error, removeDateError: newError})}
                minDateTime={formValues.eventDate}
                value={formValues.removeDate}
                slotProps={{
                  textField: { name: "removeDate" },
                  actionBar: { actions: ["today"] },
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Grid>
            <TextField
              onKeyDown={handleKeyPress}
              data-testid="banner-note"
              fullWidth
              name="notes"
              label="Note"
              type="text"
              value={formValues.notes}
              multiline={true}
              rows={4}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Grid container direction="row" spacing={4} alignItems="start">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <TextField
            onKeyDown={handleKeyPress}
            data-testid="banner-message"
            fullWidth
            name="text"
            label="Notification Message"
            type="text"
            value={formValues.text}
            multiline={true}
            rows={4}
            onChange={handleInputChange}
            helperText="Use template strings including curly braces to include date and time in message: {NOTIFY_DATE}, {NOTIFY_TIME}"
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} alignItems="start">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <DataLoadingContainer<PagedStacks>
            queryFn={queryFn}
            queryKeys={queryKeys}
            render={(data: PagedStacks) => (
              <Stacks
                dataTestId="banner-stacks"
                pagedStacks={data}
                handleFilterChange={handleFilterChange}
                handleSortChange={handleSortChange}
                handlePageChange={handlePageChange}
                handleStackSelectedChange={handleStackSelectedChange}
                getStackSelectedValue={getStackSelectedValue}
                onStackClicked={onStackClicked}
                pagingParams={pageParams}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        spacing={4}
        alignItems="start"
        marginTop={3}
      >
        <Grid item xl={1} lg={1} md={1} xs={1}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            data-testid="banner-submit"
            disabled={isPosting || isDateInvalid || formValues.stacks.length === 0}
          >
            SAVE
          </Button>
        </Grid>
        <Grid item xl={1} lg={1} md={1} xs={1}>
          <Button
            variant="contained"
            color="error"
            onClick={onCancelClicked}
            data-testid="banner-cancel"
            disabled={isPosting}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditForm;
