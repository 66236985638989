import { AlertColor } from "@mui/material/Alert";

export interface UniqueEntity {
  id: string;
  name: string;
}

export type NotificationBanner = {
  text: string;
  notes: string;
  isActive: boolean;
  eventDate: Date;
  displayDate: Date;
  removeDate: Date;
  stacks: Stack[];
  status: string;
  createdBy?: string;
  messageGroupId?: string;
};

export type Stack = {
  selected?: boolean;
  id?: string;
  name: string;
  messageId: string;
};

export type Environment = "Production" | "UAT" | "API" | "Support" | "QA";

export enum QueryKeys {
  GetNotificationBanners = "getNotificationBanners",
  PostNotificationBanners = "postAdminGroup",
  GetStacks = "getStacks",
}

export type PagedStacks = {
  servers: Stack[];
  totalCount: number;
};

export type NotificationBanners = {
  notificationBanners: NotificationBanner[];
  totalCount: number;
};

export type PagingParams = {
  page: number;
  size: number;
  sortParams?: SortParams;
  filterParams?: FilterParams;
};

export type SortParams = {
  sortField?: string;
  sortOrder?: "asc" | "desc";
};

export type FilterParams = {
  filterQuery?: string;
  filterColumn?: string;
};

export type TableHeaderParams = {
  sortable?: boolean;
  filterable?: boolean;
  title: string;
  field: string;
  width?: number; 
};

export type SelectItem = {
  id: number;
  description: string;
};

export type CreationResponse = {
  message: string;
};

export type AlertProps = {
  message: string;
  severity: AlertColor;
  showAlert: boolean;
};