import SearchableDataTable from "../../shared/searchableTable";
import {
  FilterParams,
  PagingParams,
  SortParams,
  TableHeaderParams,
  NotificationBanners
} from "../../types";
import BrinkTypography from "../../shared/brinkTypography";
import { useHistory } from "react-router";
import { dateToString, stringToDate } from "../../shared/utils/dateUtils";

type PropTypes = {
  notificationBannerData: NotificationBanners;
  pagingParams: PagingParams;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
  dataTestId?: string;
};

const tableHeaders: TableHeaderParams[] = [
  { title: "Release Date", sortable: true, filterable: true, field: "startDate" },
  { title: "Status", sortable: true, filterable: true, field: "status" },
  { title: "Notes", sortable: true, filterable: true, field: "notes" },
];

const tableColumns = [
  "startDate",
  "status",
  "notes"
];

const NotificationsTable = ({
  notificationBannerData,
  pagingParams,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
  dataTestId,
}: PropTypes): JSX.Element => {

  const history = useHistory();
  const { notificationBanners, totalCount } = notificationBannerData;
  const changeSearchQuery = (filterParams: FilterParams) => {
    handleFilterChange(filterParams);
  };
  
  const notificationBannerRows = notificationBanners.map((banner) => ({
    startDate: () => (
      <span
        onClick={() => history.push(`/detail/${banner.messageGroupId}`)}
        data-testid={`selected-messageGroupId-${banner.messageGroupId}`}
        role="button"
        tabIndex={0} >
        <BrinkTypography clickable>{banner.eventDate?.toLocaleString() === "Invalid Date" ? "" : dateToString(banner.eventDate) }</BrinkTypography>
      </span>
    ),
    id: banner.messageGroupId,
    notes: banner.notes,
    status: banner.status != null && banner.status !== "" ?
      banner.status.charAt(0).toUpperCase() + banner.status.slice(1) : 
        ""
  }));

  return (
    <SearchableDataTable
      rows={notificationBannerRows}
      tableHeaders={tableHeaders}
      pagingParams={pagingParams}
      tableColumns={tableColumns}
      tableLength={totalCount}
      changePagination={handlePageChange}
      changeSort={handleSortChange}
      changeSearchQuery={changeSearchQuery}
      dataTestId={dataTestId}
    />
  );
};

export default NotificationsTable;
