import { Checkbox } from "@mui/material";
import BrinkTypography from "../../shared/brinkTypography";
import NameSearchableDataTable from "../../shared/nameSearchableDataTable";
import SearchableDataTable from "../../shared/searchableTable";
import {
  FilterParams,
  PagingParams,
  SortParams,
  Stack,
  PagedStacks,
  TableHeaderParams,
} from "../../types";

type PropTypes = {
  pagedStacks: PagedStacks;
  pagingParams: PagingParams;
  onStackClicked: (selected: Stack) => void;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
  handleStackSelectedChange: (selected: boolean, stack: Stack) => void;
  getStackSelectedValue: (stack: Stack) => boolean;
  dataTestId?: string;
};

const tableHeaders: TableHeaderParams[] = [
  { title: "", sortable: false, filterable: false, field: "selected", width: 5 },
  { title: "Stacks", sortable: true, filterable: true, field: "name" },
];

const tableColumns = [
  "selected",
  "name",
];

const StacksTable = ({
  pagedStacks,
  onStackClicked,
  pagingParams,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
  handleStackSelectedChange,
  getStackSelectedValue,
  dataTestId,
}: PropTypes): JSX.Element => {

  const { servers, totalCount } = pagedStacks;

  const stackRows = servers.map((stack) => ({
    ...stack,
    selected: () => (
      <span
        onClick={() => selectStack(stack)}
        data-testid={`selected-stack-${stack.name}`}
        role="button"
        tabIndex={0}
      >
        <Checkbox
          checked={getStackSelectedValue(stack)}
          onChange={(e, val) => handleSelectedChange(e, stack)}
        />
      </span>
    ),
    name: () => (
      <span
        onClick={() => selectStack(stack)}
        data-testid={`stack-${stack.name}`}
        role="button"
        tabIndex={1}
      >
        <BrinkTypography>{stack.name}</BrinkTypography>
      </span>
    )
  }));

  const selectStack = (selected: Stack) => {
    onStackClicked(selected);
  };

  const changeSearchQuery = (filterParams: FilterParams) => {
    handleFilterChange(filterParams);
  };

  const handleSelectedChange = (event: React.ChangeEvent<HTMLInputElement>, stack: Stack) => {
    handleStackSelectedChange(event.target.checked, stack);
  };

  return (
    <SearchableDataTable
      rows={stackRows}
      tableHeaders={tableHeaders}
      pagingParams={pagingParams}
      tableColumns={tableColumns}
      tableLength={totalCount}
      changePagination={handlePageChange}
      changeSort={handleSortChange}
      changeSearchQuery={changeSearchQuery}
      dataTestId={dataTestId}
    />
  );
};

export default StacksTable;
