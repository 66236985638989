import { AxiosRequestConfig } from "axios";
import { PagedStacks, PagingParams, Stack } from "../types";
import HttpClient from "./HttpClient";

export type Options = {
  baseUrl: string;
  getAccessTokenAsync: () => Promise<string>;
};

export default class TopologyService extends HttpClient {
  private getAccessTokenAsync: () => Promise<string>;

  public constructor({ baseUrl, getAccessTokenAsync }: Options) {
    super(baseUrl);

    this.getAccessTokenAsync = getAccessTokenAsync;
    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError
    );
  };

  public getStacks = (pagingParams: PagingParams): Promise<PagedStacks> => {
    return this.instance.get(
      `/servers?pagenumber=${pagingParams.page}&pagesize=${pagingParams.size}${
        pagingParams.sortParams.sortField
          ? `&sortfield=${pagingParams.sortParams.sortField}&sortorder=${pagingParams.sortParams.sortOrder}`
          : ""
      }${
        pagingParams.filterParams.filterQuery
          ? `&filterquery=${pagingParams.filterParams.filterQuery}&filtercolumn=${pagingParams.filterParams.filterColumn}`
          : ""
      }`
    );
  };

  private handleRequest = async (config: AxiosRequestConfig) => {
    const { headers, ...rest } = config;

    const accessToken = await this.getAccessTokenAsync();
    headers.Authorization = accessToken;
    headers.Accept = "application/json";

    return {
      headers,
      ...rest,
    };
  };
}
