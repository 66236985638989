import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableHead, TableSortLabel } from "@material-ui/core";
import BrinkTableRow from "../../brinkTable/BrinkTableRow";
import BrinkTableCell from "../../brinkTable/BrinkTableCell";
import { FilterParams, SortParams, TableHeaderParams } from "../../../types";
import SearchBar from "../../searchBar";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EDF0F3",
    color: theme.palette.common.white,
  },
}))(BrinkTableCell);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
  blackText: {
    color: "black",
    fontWeight: "bold",
  },
  sortLabel: {
    marginBottom: 2,
    height: 30,
  },
});

type TableHeadersProps = {
  tableHeaders: TableHeaderParams[];
  sortParams?: SortParams;
  changeSort?: (sortParams: SortParams) => void;
  changeSearchQuery?: (filterParams: FilterParams) => void;
};

function TableHeader({
  tableHeaders,
  sortParams,
  changeSort,
  changeSearchQuery,
}: TableHeadersProps): JSX.Element {
  const classes = useStyles();

  return (
    <TableHead className={classes.root}>
      <BrinkTableRow dataTestId="header-row">
        {tableHeaders.map((header: TableHeaderParams) => (
          <StyledTableCell className={classes.blackText} key={header.title}>
            <div style={{ height: "50px" }}>
              {header.filterable && (
                <SearchBar
                  changeSearchQuery={changeSearchQuery}
                  filterColumn={header.field}
                />
              )}
            </div>

            {sortParams && header.sortable && (
              <TableSortLabel
                data-testid={`table-sort-label-${header.field}`}
                active={sortParams.sortField === header.title}
                direction={sortParams.sortOrder}
                className={classes.sortLabel}
                onClick={() => {
                  changeSort({
                    sortField: header.field,
                    sortOrder: sortParams.sortOrder === "asc" ? "desc" : "asc",
                  });
                }}
              >
                {header.title}
              </TableSortLabel>
            )}

            {(!sortParams || !header.sortable) && header.title}
          </StyledTableCell>
        ))}

        {/* {renderAction && (
          <StyledTableCell className={classes.blackText}>
            Actions
          </StyledTableCell>
        )} */}
      </BrinkTableRow>
    </TableHead>
  );
}

export default TableHeader;
