import {
  FilterParams,
  Stack,
  PagingParams,
  SortParams,
  PagedStacks
} from "../../types";
import StacksTable from "./StacksTable";

type PropTypes = {
  pagedStacks: PagedStacks;
  pagingParams?: PagingParams;
  onStackClicked: (selected: Stack) => void;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange?: (sortParams: SortParams) => void;
  handleFilterChange?: (filterParams: FilterParams) => void;
  handleStackSelectedChange: (selected: boolean, stack: Stack) => void;
  getStackSelectedValue: (stack: Stack) => boolean;
  dataTestId?: string;
};

const Stacks = ({
  pagedStacks,
  pagingParams,
  onStackClicked,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
  handleStackSelectedChange,
  getStackSelectedValue,
  dataTestId,
}: PropTypes): JSX.Element => {
  return (
    <StacksTable
      pagedStacks={pagedStacks}
      pagingParams={pagingParams}
      onStackClicked={onStackClicked}
      handlePageChange={handlePageChange}
      handleSortChange={handleSortChange}
      handleFilterChange={handleFilterChange}
      handleStackSelectedChange={handleStackSelectedChange}
      getStackSelectedValue={getStackSelectedValue}
      dataTestId={dataTestId}
    />
  );
};

export default Stacks;
