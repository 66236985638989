import { Route, Switch } from "react-router-dom";
import NotificationBannerService from "../../api/notificationBannerService";
import TopologyService from "../../api/toplogyService";
import NotificationBannerDetailContainer from "../notificationBannerDetail";
import NotificationBannerEditContainer from "../notificationBannerEdit";
import NotificationBannerSummaryContainer from "../notificationBannerSummary";

type PropTypes = {
  notificationBannerClient: NotificationBannerService;
  topologyClient: TopologyService;
};

const BodyContainer = ({ notificationBannerClient, topologyClient }: PropTypes): JSX.Element => {
  return (
    <Switch>
      <Route path="/new">
        <NotificationBannerEditContainer
          getStacks={topologyClient.getStacks}
          postNotificationBanner={notificationBannerClient.postNotificationBanner}
          putNotificationBanner={notificationBannerClient.putNotificationBanner}
          deleteNotificationBanner={notificationBannerClient.deleteNotificationBanner}
        />
      </Route>
      <Route path="/detail/:messageGroupId">
        <NotificationBannerDetailContainer
          getNotificationBannerByMessageGroupId={notificationBannerClient.getNotificationBannerByMessageGroupId}
        />
      </Route>
      <Route path="/edit/:messageGroupId">
        <NotificationBannerEditContainer
          getStacks={topologyClient.getStacks}
          postNotificationBanner={notificationBannerClient.postNotificationBanner}
          putNotificationBanner={notificationBannerClient.putNotificationBanner}
          deleteNotificationBanner={notificationBannerClient.deleteNotificationBanner}
        />
      </Route>
      <Route path="/summary">
        <NotificationBannerSummaryContainer
          getNotificationBanners={notificationBannerClient.getNotificationBanners}
        />
      </Route>
    </Switch>
  );
};

export default BodyContainer;
