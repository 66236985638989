import { useState } from "react";
import { dateToString, addDays, addHours } from '../../shared/utils/dateUtils'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import {
  NotificationBanner,
  PagedStacks,
  PagingParams,
  FilterParams,
  SortParams,
  Stack,
} from "../../types";

import StacksTable from "./StacksTable";
import paginate from "../../shared/datagridUtils/paginate";
import { right } from "@popperjs/core";

type PropTypes = {
  notificationBanner: NotificationBanner;
  goBackToPreviousPage: () => void;
  goToEditPage: () => void;
};

const DetailForm = ({ notificationBanner,
  goBackToPreviousPage,
  goToEditPage,
}: PropTypes) => {
  const buttonTheme = createTheme({ palette: { primary: { main: "#0A3450" } } })
  const [formValues, setFormValues] = useState(notificationBanner);

  const [pageParams, setPageParams] = useState<PagingParams>({
    page: 0,
    size: 5,
    filterParams: {} as FilterParams,
    sortParams: {} as SortParams
  });

  const filterStacksByName = (query: string, page: number): Stack[] => {
    const filteredStacks = formValues.stacks.filter((stack) => stack.name.toLowerCase().includes(query.toLowerCase()));
    return filteredStacks;
  }

  const handleFilterChange = (filterParams: FilterParams) => {
    setPageParams({ ...pageParams, filterParams, page: 0 });
    if (filterParams.filterColumn === 'name') {
      const filteredStacks = filterStacksByName(filterParams.filterQuery, 0);
      const { startIndex, endIndex } = paginate(formValues.stacks.length, 0, pageParams.size);
      setPagedStacks({ ...pagedStacks, servers: filteredStacks.slice(startIndex, endIndex), totalCount: filteredStacks.length });
    }
  };

  const handleSortChange = (sortParams: SortParams) => {
    setPageParams({ ...pageParams, sortParams });
  };

  const handlePageChange = (newPage: number, newSize: number) => {
    setPageParams({ ...pageParams, page: newPage, size: newSize } as PagingParams);
    const { startIndex, endIndex } = paginate(formValues.stacks.length, newPage, newSize);
    setPagedStacks({ ...pagedStacks, servers: formValues.stacks.slice(startIndex, endIndex) });
  };

  const onStackClicked = (params) => {

  }

  const onEditButtonClicked = (event) => {
    goToEditPage();
  };

  const onGoBackClicked = (event) => {
    event.preventDefault();
    goBackToPreviousPage();
  };

  const [pagedStacks, setPagedStacks] = useState<PagedStacks>({
    servers: Array.isArray(notificationBanner.stacks) ? notificationBanner.stacks.slice(0, pageParams.size) : [],
    totalCount: notificationBanner.stacks.length
  });

  const readOnlyTextFieldProps = {
    disableUnderline: true,
    readOnly: true,
  };

  const readOnlyLabelProps = {
    shrink: true,
  };

  return (
    <form data-testid="banner-form">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="stretch"
        alignItems="stretch"
        paddingTop={2}
      >
        <Grid item container spacing={2} xl={6} lg={6} md={6} xs={6}>
          <Grid item xl={2} lg={2} md={2} xs={2}>
            <IconButton
              data-testid="banner-goBackButton"
              size="medium"
              sx={{ padding: 0, minHeight: 0 }}
              onClick={onGoBackClicked}>
              <KeyboardArrowLeftIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Grid item xl={10} lg={10} md={10} xs={10}>
            <Typography
              variant="h5"
              color="gray"
              style={{ lineHeight: 0.8 }}>
              Notification Detail
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <TextField
              fullWidth
              label="Event Date"
              value={dateToString(formValues.eventDate)}
              data-testid="banner-eventDate"
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
          <TextField
              fullWidth
              label="Display Date"
              value={dateToString(formValues.displayDate)}
              data-testid="banner-displayDate"
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
          <TextField
              fullWidth
              label="Remove Date"
              value={dateToString(formValues.removeDate)}
              data-testid="banner-removeDate"
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <TextField
              data-testid="banner-message"
              fullWidth
              name="message"
              label="Message"
              value={formValues.text}
              multiline={true}
              rows={4}
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2} xl={6} lg={6} md={6} xs={6}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <ThemeProvider theme={buttonTheme}>
              <Button
                data-testid="banner-editButton"
                variant="contained"
                color="primary"
                onClick={onEditButtonClicked}
                style={{
                  width: 130,
                  height: 25,
                  fontSize: 13,
                  float: right,
                }}>
                Edit
              </Button>
            </ThemeProvider>
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <TextField
              data-testid="banner-createdBy"
              fullWidth
              name="createdBy"
              label="Created By"
              value={formValues.createdBy}
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <TextField
              data-testid="banner-status"
              fullWidth
              name="status"
              label="Status"
              value={formValues.status}
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <TextField
              data-testid="banner-messageGroupId"
              fullWidth
              name="messageGroupId"
              label="Notification Group ID"
              value={formValues.messageGroupId}
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <TextField
              data-testid="banner-note"
              fullWidth
              name="note"
              label="Note"
              value={formValues.notes}
              multiline={true}
              rows={4}
              variant="standard"
              InputProps={readOnlyTextFieldProps}
              InputLabelProps={readOnlyLabelProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={0} alignItems="start" height={8}>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <Typography
            variant="body1"
            color="black">
            Servers to be notified
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={0} alignItems="start">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <StacksTable
            dataTestId="banner-stacks"
            pagedStacks={pagedStacks}
            handleFilterChange={handleFilterChange}
            handleSortChange={handleSortChange}
            handlePageChange={handlePageChange}
            onStackClicked={onStackClicked}
            pagingParams={pageParams}
          />
        </Grid>
      </Grid>
    </form >);
}

export default DetailForm;