function paginate(
    totalItems: number,
    currentPageIndex: number = 0, //page numbers start from 0
    pageSize: number = 10
) {
    // calculate total pages
    let totalPagesIndex = Math.ceil(totalItems / pageSize) - 1;

    // ensure current page isn't out of range
    if (currentPageIndex < 0) {
        currentPageIndex = 0;
    } else if (currentPageIndex > totalPagesIndex) {
        currentPageIndex = totalPagesIndex;
    }

    // calculate start and end item indexes
    let startIndex = currentPageIndex * pageSize;
    let endIndex = Math.min(startIndex + pageSize, totalItems);

    // return object with all pager properties required by the view
    return {
        currentPageIndex: currentPageIndex,
        startIndex: startIndex,
        endIndex: endIndex,
    };
}

export default paginate;