import React, { useEffect } from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useCookies } from "react-cookie";
import { ThemeProvider } from "@material-ui/styles";
import Views from "./views";
import NotificationBannerService from "./api/notificationBannerService";
import TopologyService from "./api/toplogyService";
import theme from "./theme";

// We are ts ignoring this because its shared by the container app
// @ts-ignore
import { AuthTokenManager } from "@par/support-portal-auth";

const queryClient = new QueryClient();
const App = (): JSX.Element => {
  const [cookies] = useCookies(["name", "username"]);

  const accessToken = window.sessionStorage.getItem("admin_portal:token");

  const allowedUsers = [
    "kyle.senkevich@partech.com",
    "freedom_noble@partech.com",
    "Melanie.Le@partech.com",
    "behrad_farsi@partech.com",
    "Brandon_Villasin@partech.com",    
    "diane_atherton@partech.com",
    "james_brown@partech.com",
    "jamie_geiges@partech.com",
    "Leo.Graham@partech.com",
    "alex_pascual@partech.com",
    "diana_rekos@partech.com",
    "harrison_wright@partech.com",
    "hilary_wyckoff@partech.com",
    "Ian.Bernstein@partech.com",
    "adam_pullen@partech.com",
    "andrew_rajcoomar@partech.com",
    "Ankush_Khurana@partech.com",
    "Elvis_Tran@partech.com",
    "jason_white@partech.com",
    "jeremy_burchett@partech.com",
    "lakshmi_shree@partech.com",
    "minjung_kang@partech.com",
    "trisha_mumford@partech.com",
    "troy_kennedy@partech.com",
    "Derek_Puttmann@partech.com",
    "bianca_pena@partech.com",
    "jason_scruggs@partech.com",
    "Chris_Wright@partech.com",
    "Malania_Wilson@partech.com",
    "zachary_fickas@partech.com",
    "eddie_cardenas@partech.com",
    "Poulomi_Adhikari@partech.com",
    "angelle_humble@partech.com",
    "dominic_bindley@partech.com",
    "Giovanni_Bucciero@partech.com",
    "Jerry_Michels@partech.com",
    "alex.huang@partech.com",
    "Dushyant.Sharma@partech.com",
    "Hitesh_Sharma@partech.com",
    "Ahamad_Kutagolla@partech.com",
  ].map((user) => user.toLowerCase());

  console.log("Logged in user: " + cookies.username.toLowerCase());

  useEffect(() => {
    if (!cookies.username || !allowedUsers.includes(cookies.username.toLowerCase())) {
      console.log("User: " + cookies.username + " is not allowed");
      alert("User: " + cookies.username + " is not allowed to access this page. Please contact the administrator.");
      window.location.href = '/';
    }
  }, [cookies.username, allowedUsers]);

  const baseUrl = process.env.NOTIFICATIONBANNER_SERVICE_BASEURL;
  const topologyServiceUrl = process.env.TOPOLOGY_SERVICE_BASEURL;

  const notificationBannerClient = new NotificationBannerService({
    baseUrl,
    getAccessTokenAsync: () => AuthTokenManager.getTokenAsync(),
  });

  const topologyClient = new TopologyService({
    baseUrl: topologyServiceUrl,
    getAccessTokenAsync: () => AuthTokenManager.getTokenAsync(),
  });

  // TODO: Redirect to root if cookie does not contain username
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router basename="/notificationBanner">
            <Views notificationBannerClient={notificationBannerClient} topologyClient={topologyClient} />
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
