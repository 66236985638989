import { createTheme } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0A3450",
    },
    secondary: {
      main: "#B8C1CB",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  shadows: Array(25).fill("none") as Shadows,
});

export default theme;
