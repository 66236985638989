/* eslint-disable react/jsx-no-duplicate-props */
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@material-ui/core";
import { FilterParams } from "../../types";

export type SearchBarProps = {
  changeSearchQuery: (filterParams: FilterParams) => void;
  filterColumn: string;
  placeHolder?: string;
};

const SearchBar = ({
  changeSearchQuery,
  filterColumn,
  placeHolder = "Search",
}: SearchBarProps): JSX.Element => {
  const [filterQuery, setFilterQuery] = useState("");

  // Pathname is used for keeping localstorage saved searched unique as some pages both use filterColumn "name"
  const { pathname } = window.location;

  // When changeSearchQuery is called we should save the current query to local storage
  const performSearch = () => {
    changeSearchQuery({ filterQuery, filterColumn });
  };

  return (
    <div>
      <TextField
        label={placeHolder}
        value={filterQuery}
        inputProps={{
          "data-testid": `search-input-${filterColumn}`,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid={`search-button-${filterColumn}`}
                onClick={(e) => {
                  performSearch();
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => setFilterQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            performSearch();
          }
        }}
      />
    </div>
  );
};

export default SearchBar;
