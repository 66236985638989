import { Typography } from "@mui/material";
import { ElementType } from "react";
import "./clickableTypography.css";

export type BrinkTypographyProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  color?:
  | "initial"
  | "inherit"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error";
  variant?: "inherit"
  | "button"
  | "caption"
  | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  | "subtitle1" | "subtitle2"
  | "body1" | "body2"
  | "overline";
  className?: string;
  dataTestId?: string;
  component?: ElementType;
  key?: string;
  clickable?: boolean;
};

const BrinkTypography = ({
  dataTestId = null,
  component = null,
  className = null,
  children = null,
  color = "initial",
  variant = "inherit",
  clickable = false,
}: BrinkTypographyProps): JSX.Element => {
  return (
    <Typography
      className={`${clickable ? "clickable-typography" : ""} ${className}`}
      data-testid={dataTestId}
      component={component}
      variant={variant}
      color={color}
    >
      {children}{" "}
    </Typography>
  );
};

export default BrinkTypography;
