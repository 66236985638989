const momentTz = require('moment-timezone');
const moment = require('moment');

export const dateToString = (
  date: Date,
): string => {
  const zoneName = moment.tz.guess();
  return momentTz(date).tz(zoneName).format('MM/DD/YYYY - hh:mm A z');
}

export const stringToDate = (
  date: string,
): Date => {
    const zoneName = moment.tz.guess();
  return momentTz.tz(date, 'MM/DD/YYYY - hh:mm A z', zoneName).toDate();
}

export const dateToDatePickerString = (
  date: Date,
): string => {
  return date.toLocaleString("en-US",
    {
      day: '2-digit', month: '2-digit', year: 'numeric',
      hour: '2-digit', minute: '2-digit', hour12: true,
    })
    .replace(/,/g, "")
    .toLowerCase();
}

export const addDays = (
  date: Date,
  days: number,
): Date => {
  let newDate = moment(date).add(days, 'days');
  return newDate.toDate();
}

export const addHours = (
  date: Date,
  hours: number,
): Date => {
  let newDate = moment(date).add(hours, 'hours');
  return newDate.toDate();
}