import { NotificationBannerDto } from "../dtos";
import { AxiosRequestConfig } from "axios";
import { convertToNotificationBannerDto } from "../shared/modelConverters/notificationBannerConverters";
import { NotificationBanner } from "../types";
import HttpClient from "./HttpClient";

export type Options = {
  baseUrl: string;
  getAccessTokenAsync: () => Promise<string>;
};

export default class NotificationBannerService extends HttpClient {
  private getAccessTokenAsync: () => Promise<string>;

  public constructor({ baseUrl, getAccessTokenAsync }: Options) {
    super(baseUrl);

    this.getAccessTokenAsync = getAccessTokenAsync;
    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError
    );
  };

  public postNotificationBanner = (
    notificationBanner: NotificationBanner
  ): Promise<string> => {
    const dtos = convertToNotificationBannerDto(notificationBanner);
    return this.instance.post(`/NotificationBanner`, dtos);
  };

  public putNotificationBanner = (
    notificationBanner: NotificationBanner
  ): Promise<string> => {
    const dto = convertToNotificationBannerDto(notificationBanner);
    return this.instance.put(
      `/NotificationBanner/${notificationBanner.messageGroupId}`,
      dto
    );
  };

  public deleteNotificationBanner = (
    notificationBanner: NotificationBanner
  ): Promise<string> => {
    return this.instance.delete(
      `/NotificationBanner/${notificationBanner.messageGroupId}`
    );
  };

  public getNotificationBannerByMessageGroupId = (
    messageGroupId: string
  ): Promise<NotificationBannerDto[]> =>
    this.instance.get(`/NotificationBanner/group/${messageGroupId}`);

  public getNotificationBanners = (): Promise<Array<NotificationBannerDto>> =>
    this.instance.get(`/NotificationBanner/all`);

  private handleRequest = async (config: AxiosRequestConfig) => {
    const { headers, ...rest } = config;

    const accessToken = await this.getAccessTokenAsync();
    headers.Authorization = accessToken;
    headers.Accept = "application/json";

    return {
      headers,
      ...rest,
    };
  };
}
