import { Container, Box } from "@material-ui/core";
import NotificationBannerService from "../api/notificationBannerService";
import TopologyService from "../api/toplogyService";
import BodyContainer from "./body";
import HeaderContainer from "./header";

type PropTypes = {
  notificationBannerClient: NotificationBannerService;
  topologyClient: TopologyService;
};

const Views = ({
  notificationBannerClient,
  topologyClient,
}: PropTypes): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" marginRight={2}>
      <HeaderContainer notificationBannerClient={notificationBannerClient} />
      <BodyContainer
        notificationBannerClient={notificationBannerClient}
        topologyClient={topologyClient}
      />
    </Box>
  );
};

export default Views;
