import { LinearProgress } from "@material-ui/core";
import SummaryForm from "./SummaryForm";
import { NotificationBanner } from "../../types";
import { useState, useEffect } from "react";
import { convertToNotificationBanner } from "../../shared/modelConverters/notificationBannerConverters";
import { NotificationBannerDto } from "../../dtos";

type PropTypes = {
  getNotificationBanners: () => Promise<Array<NotificationBannerDto>>;
};

const NotificationBannerSummaryContainer = ({ getNotificationBanners }: PropTypes): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);

  //TODO: test data for demo
  const [notificationBanners, setNotificationBanners] = useState<Array<NotificationBanner>>();

  useEffect(() => {
    async function getNotificationBannersFromApi() {
      getNotificationBanners().then((banners) => {
        const convertedBanners = convertToNotificationBanner(banners);
        setNotificationBanners(convertedBanners);
        setIsLoading(false);
      }, function (error) { alert(error); });
    }

    setIsLoading(true);
    getNotificationBannersFromApi();
  }, []);

  if (isLoading) {
    return <LinearProgress data-testid="linear-progress" />;
  }
  else {
    return (
      <div data-testid="notificationBannerSummaryContainer">
        <SummaryForm notificationBanners={notificationBanners} />
      </div>
    );
  }
};

export default NotificationBannerSummaryContainer;
