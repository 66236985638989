import { Switch, Route } from "react-router-dom";
import NotificationBannerService from "../../api/notificationBannerService";

type PropTypes = {
  notificationBannerClient: NotificationBannerService;
};

const HeaderContainer = ({ notificationBannerClient }: PropTypes): JSX.Element => {
  return (
    <Switch>
      
    </Switch>
  );
};

export default HeaderContainer;
