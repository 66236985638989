import { NotificationBannerDto } from "../../dtos";
import { NotificationBanner, Stack } from "../../types";

export const convertToNotificationBannerDto = (notificationBanner: NotificationBanner): NotificationBannerDto[] => {
  if (notificationBanner == null) {
    return null;
  }

  let eventDate: Date = null;
  if (notificationBanner.eventDate != null && notificationBanner.eventDate instanceof Date) {
    eventDate = notificationBanner.eventDate;
  }
  
  let displayDate: Date = null;
  if (notificationBanner.displayDate != null && notificationBanner.displayDate instanceof Date) {
    displayDate = notificationBanner.displayDate;
  }

  let removeDate: Date = null;
  if (notificationBanner.removeDate != null && notificationBanner.removeDate instanceof Date) {
    removeDate = notificationBanner.removeDate;
  }

  const dtos: NotificationBannerDto[] = notificationBanner.stacks.map<NotificationBannerDto>(
    (el, index) => {
      return {
        Notes: notificationBanner.notes,
        StackId: el.name,
        Text: notificationBanner.text,
        MessageId: notificationBanner.stacks[index].messageId,
        IsActive: notificationBanner.isActive,
        EventDate: eventDate,
        DisplayDate: displayDate,
        RemoveDate: removeDate,
        Status: notificationBanner.status,
        MessageGroupId: notificationBanner.messageGroupId,
        CreatedBy: notificationBanner.createdBy,
      };
    }
  );

  return dtos;
};

export const convertToNotificationBanner = (notificationBannerDtos: NotificationBannerDto[]): NotificationBanner[] => {
  if (notificationBannerDtos == null || notificationBannerDtos.length == 0) {
    return null;
  }

  const groupedByMessageGroupIds = notificationBannerDtos.reduce(function (
    acc,
    obj
  ) {
    const key = obj.MessageGroupId;
    const curGroup = acc[key] ?? [];
    return { ...acc, [key]: [...curGroup, obj] };
  },
    {});

  const notificationBanners: NotificationBanner[] = [];
  for (const key in groupedByMessageGroupIds) {
    const messages = groupedByMessageGroupIds[key] as NotificationBannerDto[];
    const message = messages[0];

    let eventDate = message.EventDate;
    if (message.EventDate instanceof String || message.EventDate.constructor.name == "String") {
      eventDate = new Date(message.EventDate);
    }

    let displayDate = message.DisplayDate;
    if (message.DisplayDate instanceof String || message.EventDate.constructor.name == "String") {
      displayDate = new Date(message.DisplayDate);
    }

    let removeDate = message.RemoveDate;
    if (message.RemoveDate instanceof String || message.RemoveDate.constructor.name == "String") {
      removeDate = new Date(message.RemoveDate);
    }


    notificationBanners.push({
      isActive: message.IsActive,
      notes: message.Notes,
      status: message.Status,
      text: message.Text,
      messageGroupId: message.MessageGroupId,
      stacks: messages.map<Stack>((el) => {
        return {
          id: el.StackId,
          name: el.StackId,
          selected: true,
          messageId: el.MessageId,
        };
      }),
      eventDate: eventDate,
      displayDate: displayDate,
      removeDate: removeDate,
      createdBy: message.CreatedBy,
    });
  }

  return notificationBanners;
};
