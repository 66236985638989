import BrinkTypography from "../../shared/brinkTypography";
import SearchableDataTable from "../../shared/searchableTable";
import {
  FilterParams,
  PagingParams,
  SortParams,
  Stack,
  PagedStacks,
  TableHeaderParams,
} from "../../types";

type PropTypes = {
  pagedStacks: PagedStacks;
  pagingParams: PagingParams;
  onStackClicked: (selected: Stack) => void;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
  dataTestId?: string;
};

const tableHeaders: TableHeaderParams[] = [
  { title: "Stacks", sortable: true, filterable: true, field: "name" },
];

const tableColumns = [
  "name"
];

const StacksTable = ({
  pagedStacks: stacksData,
  onStackClicked,
  pagingParams,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
  dataTestId,
}: PropTypes): JSX.Element => {

  const selectStack = (selected: Stack) => {
    onStackClicked(selected);
  };

  const changeSearchQuery = (filterParams: FilterParams) => {
    handleFilterChange(filterParams);
  };

  const { servers: stacks, totalCount } = stacksData;

  const stackRows = stacks.map((stack) => ({
    ...stack,
    name: () => (
      <span
        onClick={() => selectStack(stack)}
        data-testid={`stack-${stack.name}`}
        role="button"
        tabIndex={1}
      >
        <BrinkTypography>{stack.name}</BrinkTypography>
      </span>
    )
  }));

  return (
    <SearchableDataTable
      rows={stackRows}
      tableHeaders={tableHeaders}
      pagingParams={pagingParams}
      tableColumns={tableColumns}
      tableLength={totalCount}
      changePagination={handlePageChange}
      changeSort={handleSortChange}
      changeSearchQuery={changeSearchQuery}
      dataTestId={dataTestId}
    />
  );
};

export default StacksTable;
