import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";

export type BrinkTableRowProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  dataTestId?: string;
  key?: string;
  hover?: boolean;
  role?: string;
  tabIndex?: number;
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
    padding: 0,
    "&:nth-of-type(even)": {
      backgroundColor: "#F3F4F5",
    },
  },
}))(TableRow);

const BrinkTableRow = ({
  dataTestId = null,
  children = null,
  key = null,
  hover = false,
  role = null,
  tabIndex = null,
}: BrinkTableRowProps): JSX.Element => {
  return (
    <StyledTableRow
      data-testid={dataTestId}
      key={key}
      hover={hover}
      role={role}
      tabIndex={tabIndex}
    >
      {children}
    </StyledTableRow>
  );
};

export default BrinkTableRow;
