import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import DetailForm from "./DetailForm";
import {
  NotificationBanner,
} from "../../types";
import { useState, useEffect } from "react";
import { convertToNotificationBanner, } from "../../shared/modelConverters/notificationBannerConverters";
import { NotificationBannerDto } from "../../dtos";

type PropTypes = {
  getNotificationBannerByMessageGroupId: (messageGroupId: string) => Promise<NotificationBannerDto[]>;
};

type MessageGroupParams = {
  messageGroupId: string;
};

const NotificationBannerDetailContainer = ({
  getNotificationBannerByMessageGroupId,
}: PropTypes): JSX.Element => {
  const { messageGroupId } = useParams<MessageGroupParams>();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const [notificationBanner, setNotificationBanner] = useState<NotificationBanner>();

  const goBackToPreviousPageHandle = () => {
    history.push(`/summary`);
  };

  const goToEditPageHandle = () => {
    history.push({ pathname: `/edit/${notificationBanner.messageGroupId}`, state: { isEdit: true, banner: notificationBanner } });
  };

  useEffect(() => {
    getNotificationBannerByMessageGroupId(messageGroupId)
      .then((notificationBannerDtos) => {
        const notificationBanners = convertToNotificationBanner(notificationBannerDtos);

        if (notificationBanners.length != 1) {
          console.error("Upgrade banners do not match with one MessageGroupId.")
        }
        setNotificationBanner({ ...notificationBanners[0] });
        setIsLoading(false);
      },
        function (error) {
          console.error(`Unable to fetch upgrade banners by messageGroupId, Message=${error}`);
        });
  }, []);

  if (isLoading) {
    return <LinearProgress data-testid="linear-progress" />;
  }
  else {
    return (
      <div data-testid="notificationBannerEditContainer">
        <DetailForm
          notificationBanner={notificationBanner}
          goBackToPreviousPage={goBackToPreviousPageHandle}
          goToEditPage={goToEditPageHandle}
        />
      </div>
    );
  }
};

export default NotificationBannerDetailContainer;
