import { useHistory } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import EditForm from "./EditForm";
import {
  NotificationBanner,
  PagingParams,
  PagedStacks,
  AlertProps,
} from "../../types";
import { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { addDays } from "../../shared/utils/dateUtils";

type PropTypes = {
  getStacks: (pagingParams: PagingParams) => Promise<PagedStacks>;
  postNotificationBanner: (NotificationBanner) => Promise<string>;
  putNotificationBanner: (NotificationBanner) => Promise<string>;
  deleteNotificationBanner: (NotificationBanner) => Promise<string>;
};

const NotificationBannerEditContainer = ({
  getStacks,
  postNotificationBanner,
  putNotificationBanner,
  deleteNotificationBanner
}: PropTypes): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPosting, setIsPosting] = useState(false);
  const [existingNotificationBanner, setExistingNotificationBanner] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(["username"]);

  const history = useHistory();
  const location = useLocation();

  const [defaultNotificationBanner, setDefaultNotificationBanner] =
    useState<NotificationBanner>({
      eventDate: new Date(),
      displayDate: new Date(),
      removeDate: addDays(new Date(), 1),
      text: "",
      isActive: false,
      notes: "",
      stacks: [],
      createdBy: "",
      messageGroupId: "",
      status: "",
    });

  const [alertProps, setAlertProps] = useState<AlertProps>({
    showAlert: false,
    message: "",
    severity: "success",
  });

  const goBackToPreviousPageHandle = () => {
    history.push(`/summary`);
  };

  const deleteBanner = (notificationBanner: NotificationBanner) => {
    setIsPosting(true);
    deleteNotificationBanner(notificationBanner).then(
      (response) => {
        setAlertProps({
          ...alertProps,
          message: response,
          severity: "success",
          showAlert: true,
        });
      },
      (error) => {
        setAlertProps({
          ...alertProps,
          message: error?.response?.data,
          severity: "error",
          showAlert: true,
        });
        setIsPosting(false);
      }
    );
  };

  const goToSummaryPage = () => {
    history.push(`/summary`);
  };

  const createNotificationBanner = (notificationBanner: NotificationBanner) => {
    setIsPosting(true);
    notificationBanner.createdBy = cookies.username;
    postNotificationBanner(notificationBanner).then(
      (response) => {
        setAlertProps({
          ...alertProps,
          message: response,
          severity: "success",
          showAlert: true,
        });
      },
      (error) => {
        setAlertProps({
          ...alertProps,
          message: error?.response?.data,
          severity: "error",
          showAlert: true,
        });
        setIsPosting(false);
      }
    );
  };

  const updateNotificationBanner = (notificationBanner: NotificationBanner) => {
    setIsPosting(true);
    notificationBanner.createdBy = cookies.username;
    putNotificationBanner(notificationBanner).then(
      (response) => {
        setAlertProps({
          ...alertProps,
          message: response,
          severity: "success",
          showAlert: true,
        });
      },
      (error) => {
        setAlertProps({
          ...alertProps,
          message: error?.response?.data,
          severity: "error",
          showAlert: true,
        });
        setIsPosting(false);
      }
    );
  };

  useEffect(() => {
    if (location.state?.banner != null) {
      setExistingNotificationBanner(location.state?.banner);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LinearProgress data-testid="linear-progress" />;
  } else {
    return (
      <div data-testid="notificationBannerEditContainer">
        <EditForm
          notificationBanner={
            existingNotificationBanner ? existingNotificationBanner : defaultNotificationBanner
          }
          postNotificationBanner={createNotificationBanner}
          goBackToPreviousPage={goBackToPreviousPageHandle}
          putNotificationBanner={updateNotificationBanner}
          isPosting={isPosting}
          cancel={goToSummaryPage}
          getStacks={getStacks}
          isEdit={existingNotificationBanner ? true : false}
          deleteNotificationBanner={deleteBanner}
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          open={alertProps.showAlert}
          onClose={() => {
            setAlertProps({ ...alertProps, showAlert: false });
            goToSummaryPage();
          }}
        >
          <Alert severity={alertProps.severity} hidden={!alertProps.showAlert}>
            {alertProps.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
};

export default NotificationBannerEditContainer;
